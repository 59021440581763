require('fslightbox');

/**
 * Opens gallery
 * @param {Object} data 
 */
const openGallery = (data, start) => {
    if (data) {
        const lightbox = new FsLightbox();
        lightbox.props.sources = data;
        lightbox.stageIndexes = {current: start};
        lightbox.open();
    }
}

/**
 * Get gallery
 * @param {Number} id 
 * @param {String} source 
 * @param {Number|null} start
 */
const getGallery = (id, source, start) => {

    const url = new URL(window.location.origin + source);
    url.searchParams.append('id', id);

    const xhr = new XMLHttpRequest();
    start = ((start) ? parseInt(start) : 0)
    xhr.open('GET', url, true);
    xhr.responseType = "json";
    xhr.onload = () => {
        if (xhr.status === 200) {
            openGallery(xhr.response, start);
        }
    };
    xhr.send();
}

/**
 * Gallery initialisation
 * @param {Element} el 
 */
const init = (el) => {
    const id = el.getAttribute('data-gallery-id');
    const source = el.getAttribute('data-source');
    const start = el.getAttribute('data-start');
    if (id && source) {
        getGallery(parseInt(id), source, start);
    }
}

// Event handler and gallery init
document.addEventListener('click', (e) => {
    if (e.target.matches('[data-gallery-id]')) {
        e.preventDefault();
        init(e.target);
    }
});